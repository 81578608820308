import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class WishListProducts extends HttpFactory {
  private readonly resource = 'v1/wishListProducts'

  public createWishListProduct (productId: string) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ productId, ...(!token && guestId ? { guestId } : {}) }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public getWishListProducts (params: object) {
    const { guestId, token } = this.authStore
    const config = { params }

    if (token) {
      (config as any).headers = getAuthHeaders(token)
    } else if (guestId) {
      (config.params as any).guestId = guestId
    }

    return this.$fetch(this.resource, config)
  }

  public deleteWishListProduct (productId: string) {
    const { guestId, token } = this.authStore
    const config: any = { method: 'DELETE' }

    if (token) {
      (config as any).headers = getAuthHeaders(token)
    } else if (guestId) {
      (config as any).params = { guestId }
    }

    return this.$fetch(`${this.resource}/${productId}`, config)
  }
}
