import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class CartProducts extends HttpFactory {
  private readonly resource = 'v1/cartProducts'

  public createCartProduct (productId: string, quantity: number) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ productId, quantity, ...(!token && guestId ? { guestId } : {}) }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public createCartProductsByCollectionId (collectionId: string) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ collectionId, ...(!token && guestId ? { guestId } : {}) }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public createCartProductsByListId (listId: string) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ listId, ...(!token && guestId ? { guestId } : {}) }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public createCartProductsByOrderId (orderId: string) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public createCartProductsByProductIds (productIds: string[]) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ productIds, ...(!token && guestId ? { guestId } : {}) }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public getCartProducts (params: object) {
    const { guestId, token } = this.authStore
    const config: any = { params }

    if (token) {
      config.headers = getAuthHeaders(token)
    } else if (guestId) {
      config.params.guestId = guestId
    }

    return this.$fetch(this.resource, config)
  }

  public updateCartProduct (productId: string, quantity: number) {
    const { guestId, token } = this.authStore
    const data: any = { quantity }
    let headers: any = { 'Content-Type': 'application/json' }

    if (token) {
      headers = { ...headers, ...getAuthHeaders(token) }
    } else if (guestId) {
      data.guestId = guestId
    }

    return this.$fetch(
      `${this.resource}/${productId}`,
      { method: 'PATCH', body: JSON.stringify(data), headers }
    )
  }

  public deleteCartProduct (productId: string) {
    const { guestId, token } = this.authStore
    const config: any = { method: 'DELETE' }

    if (token) {
      config.headers = getAuthHeaders(token)
    } else if (guestId) {
      config.params = { guestId }
    }

    return this.$fetch(`${this.resource}/${productId}`, config)
  }

  public deleteCartProducts (productIds: string[]) {
    const { guestId, token } = this.authStore
    const data: any = { productIds }
    let headers: any = { 'Content-Type': 'application/json' }

    if (token) {
      headers = { ...headers, ...getAuthHeaders(token) }
    } else if (guestId) {
      data.guestId = guestId
    }

    return this.$fetch(this.resource, { method: 'DELETE', body: JSON.stringify(data), headers })
  }
}
