import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Collections extends HttpFactory {
  private readonly resource = 'v1/collections'

  public createCollection (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getCollection (collectionId: string, params?: object) {
    return this.$fetch(
      `${this.resource}/${collectionId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getCollections (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateCollection (collectionId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${collectionId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteCollection (collectionId: string) {
    return this.$fetch(
      `${this.resource}/${collectionId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
