import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Services extends HttpFactory {
  private readonly resource = 'v1/socialNetworks'

  public getSocialNetworks (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }
}
