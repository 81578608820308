import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import AuthScope from '~/enums/auth-scope'

export default class SupplierApplications extends HttpFactory {
  private readonly resource = 'v1/supplierApplications'

  public createSupplierApplication (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getSupplierApplication (supplierApplicationId: string) {
    return this.$fetch(
      `${this.resource}/${supplierApplicationId}`,
      { headers: getAuthHeaders(this.authStore.token, AuthScope.Admin) }
    )
  }

  public getSupplierApplications (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, AuthScope.Admin), params })
  }

  public updateSupplierApplication (supplierApplicationId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${supplierApplicationId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Admin) }
      }
    )
  }
}
