import HttpFactory from '~/api/http-factory'
import type AuthProvider from '~/enums/auth-provider'

export default class Auth extends HttpFactory {
  private readonly resource = 'auth'

  public changePassword (password: string, passwordResetToken: string) {
    return this.$fetch(
      `${this.resource}/changePassword`,
      {
        method: 'POST',
        body: JSON.stringify({ password, token: passwordResetToken }),
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }

  public completeAuthFlow (authProvider: AuthProvider, payload: object, guestId: string | null) {
    const data = { authProvider, guestId, payload }

    return this.$fetch(
      `${this.resource}/completeAuthFlow`,
      { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } }
    )
  }

  public getAuthUrl (authProvider: AuthProvider) {
    const data = { authProvider }

    return this.$fetch(
      `${this.resource}/getAuthUrl`,
      { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } }
    )
  }

  public getToken (email: string, password: string) {
    const { guestId } = this.authStore
    const data = { email, password }

    if (guestId) {
      (data as any).guestId = guestId
    }

    return this.$fetch(`${this.resource}/getToken`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public register (email: string, password: string) {
    return this.$fetch(
      `${this.resource}/register`,
      {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }

  public resetPassword (email: string) {
    return this.$fetch(
      `${this.resource}/resetPassword`,
      {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }

  public verifyEmail (emailConfirmationToken: string) {
    return this.$fetch(
      `${this.resource}/verifyEmail`,
      {
        method: 'POST',
        body: JSON.stringify({ token: emailConfirmationToken }),
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }
}
