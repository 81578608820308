import type { FetchError } from 'ofetch'

const defaultErrorMessage = 'Произошла неизвестная ошибка.'
const errorMessageMapping: { [key: string]: string } = {
  // Actions. Monthly bonuses.
  CONDITIONS_FOR_RECEIVING_MONTHLY_BONUS_ARE_NOT_MET: 'Не выполнены условия для получения ежемесячного бонуса.',
  MONTHLY_BONUS_HAS_ALREADY_BEEN_RECEIVED: 'Ежемесячный бонус уже был начислен.',

  // Cart.
  CANNOT_CREATE_ORDER: 'Не удалось создать заказ.',
  EMPTY_CART: 'Пустая корзина.',
  ORDER_SUBTOTAL_AMOUNT_IS_TOO_SMALL: 'Сумма товаров в заказе слишком мала.',
  REQUESTED_QUANTITY_IS_NOT_AVAILABLE: 'Некоторых товаров нет в нужном количестве. Оформить заказ можно только после уменьшения их количества.',
  REQUESTED_QUANTITY_IS_NOT_MULTIPLE_OF_SPECIFIED_VALUE: 'Запрошенное количество некоторых товаров не кратно указанному значению. Оформить заказ можно только после корректировки количества.',
  SOME_PRODUCTS_ARE_NOT_PUBLISHED: 'Некоторые товары не опубликованы. Оформить заказ можно только после их удаления из корзины.',
  SOME_PRODUCTS_ARE_NOT_SHIPPED_BY_SELECTED_METHOD: 'Некоторые товары не доставляются выбранным способом.',
  SOME_PRODUCTS_ARE_ONLY_AVAILABLE_IN_MOSCOW: 'Некоторые товары доставляются только по Москве.',
  SOME_PRODUCTS_ARE_OUT_OF_STOCK: 'Некоторых товаров нет в наличии. Оформить заказ можно только после их удаления из корзины.',

  // Certificates.
  CERTIFICATE_HAS_ALREADY_BEEN_ACTIVATED: 'Сертификат уже был активирован.',
  CERTIFICATE_HAS_EXPIRED: 'Срок действия сертификата истёк.',
  CERTIFICATE_IS_NOT_FOUND: 'Сертификат не найден.',

  // Lists.
  LIST_WITH_THIS_NAME_ALREADY_EXISTS: 'Список с таким названием уже существует.',

  // Orders.
  CANNOT_GET_PAYMENT_URL: 'Не удалось получить ссылку на оплату.',
  ORDER_HAS_ALREADY_BEEN_PAID: 'Заказ уже был оплачен.',
  PAYMENT_URL_IS_EXPIRED: 'Истёк срок действия ссылки на оплату.',
  WRONG_ORDER_STATUS: 'Неправильный статус заказа.',

  // Points.
  MAXIMUM_NUMBER_OF_POINTS_FOR_ORDER_HAS_BEEN_EXCEEDED: 'Превышено максимальное количество баллов на заказ.',
  POINTS_ARE_NOT_COMPATIBLE_WITH_ANOTHER_POINTS: 'Баллы несовместимы с другими баллами.',
  POINTS_ARE_NOT_COMPATIBLE_WITH_PROMO_CODES: 'Баллы несовместимы с промокодами.',
  POINTS_MUST_BE_POSITIVE_INTEGERS: 'Баллы должны быть положительными целыми числами.',

  // Promo codes.
  MINIMUM_TOTAL_PRODUCTS_QUANTITY_IS_REQUIRED: 'Требуется минимальное общее количество товаров.',
  PROMO_CODE_HAS_EXPIRED: 'Срок действия промокода истёк.',
  PROMO_CODE_IS_APPLICABLE_ONLY_ON_FIRST_ORDER: 'Этот промокод можно применить только на первый заказ.',
  PROMO_CODE_IS_NOT_APPLICABLE_TO_SELECTED_PRODUCTS: 'Промокод не распространяется на выбранные товары.',
  PROMO_CODE_IS_NOT_FOUND: 'Промокод не найден.',
  PROMO_CODE_IS_NOT_VALID_FOR_SALE_ITEMS: 'Промокод недействителен для товаров со сниженной ценой.',
  PROMO_CODE_SPECIAL_CONDITIONS_ARE_NOT_MET: 'Специальные условия промокода не выполнены.',
  PROMO_CODE_USAGE_LIMIT_HAS_BEEN_REACHED: 'Достигнут лимит использования промокода.',
  UNKNOWN_DISCOUNT_TYPE: 'Неизвестный тип скидки.',

  // Tests.
  INVALID_TEST_RESULT_DATA: 'Неверные данные результата теста.'
}

export default class ApiErrorHandler {
  public static getMessage (apiErrorCode: string, commonErrorMessage: string | null = null): string {
    return errorMessageMapping[apiErrorCode] ?? commonErrorMessage ?? defaultErrorMessage
  }

  public static getMessageFromData (
    data: { errors: [{ code?: string, detail?: string }] },
    commonErrorMessage: string
  ): string {
    let apiErrorCode = null
    let errorDetail = null

    if (data && Array.isArray(data.errors) && data.errors.length) {
      const error = data.errors[0]

      if (error.code) {
        apiErrorCode = error.code
      }

      if (error.detail) {
        errorDetail = error.detail
      }
    }

    if (apiErrorCode) {
      return this.getMessage(apiErrorCode, commonErrorMessage)
    }

    if (typeof errorDetail === 'string') {
      return errorDetail
    }

    return commonErrorMessage ?? defaultErrorMessage
  }

  public static handleFetchError (fetchError: FetchError, commonErrorMessage: string): void {
    console.error(fetchError)

    useNuxtApp().$toast.error(
      fetchError.data
        ? this.getMessageFromData(fetchError.data, commonErrorMessage)
        : commonErrorMessage
    )
  }
}
