enum AuthScope {
  Admin = 'admin',
  Client = 'client',
  Specialist = 'specialist',
  Supplier = 'supplier'
}

const { Admin, Client, Specialist, Supplier } = AuthScope

const userSectionNameMapping = new Map([
  [Admin, 'Админпанель'],
  [Client, 'ЛК клиента'],
  [Specialist, 'ЛК специалиста'],
  [Supplier, 'ЛК поставщика']
])

namespace AuthScope {
  export function getUserSectionName (authScope: AuthScope): string {
    return userSectionNameMapping.get(authScope)!
  }
}

export default AuthScope
