import constants from '~/constants'

export default defineNuxtRouteMiddleware((to) => {
  const { hash, path, query } = to
  const { dataProtectionPolicy, offerForSuppliers, userAgreement } = constants.documentUrls
  const externalOptions = { external: true, redirectCode: 301 }

  switch (path) {
    case '/data-protection-policy/':
      return navigateTo(dataProtectionPolicy, externalOptions)
    case '/offer-for-sellers/':
      return navigateTo(offerForSuppliers, externalOptions)
    case '/quiz/':
      return navigateTo({ hash, path: '/tests/ekspress-test-6/', query }, { redirectCode: 301 })
    case '/user-agreement/':
      return navigateTo(userAgreement, externalOptions)
    default:
      break
  }
})
