import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class SpecialOffers extends HttpFactory {
  private readonly resource = 'v1/specialOffers'

  public createSpecialOffer (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialOffer (specialOfferId: string, params?: object) {
    return this.$fetch(
      `${this.resource}/${specialOfferId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getSpecialOffers (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateSpecialOffer (specialOfferId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${specialOfferId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteSpecialOffer (specialOfferId: string) {
    return this.$fetch(
      `${this.resource}/${specialOfferId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
