import { defineStore } from 'pinia'

type State = { guestId: string | null, token: string | null, userId: string | null }

const initialState = (): State => ({ guestId: null, token: null, userId: null })

export const useAuthStore = defineStore('auth', {
  state: () => initialState(),

  getters: {
    isGuest (state: State): boolean {
      return state.guestId !== null
    },
    isUser (state: State): boolean {
      return state.token !== null && state.userId !== null
    }
  },

  actions: {
    initializeStore (initialState: State): void {
      Object.assign(this, initialState)
    },
    setGuestId (guestId: string | null): void {
      this.guestId = guestId
    },
    setToken (token: string | null): void {
      this.token = token
    },
    setUserId (userId: string | null): void {
      this.userId = userId
    }
  }
})
