<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    ariaLabel?: string
    color: 'black' | 'blue' | 'body-background' | 'gray' | 'green' | 'light-gray' | 'light-yellow' | 'red' | 'white'
    icon?: string | null
    isBlueOnHover?: boolean
    isDisabled?: boolean
    isGreenOnHover?: boolean
    isIconOnly?: boolean
    isLoading?: boolean
    isRedOnHover?: boolean
    isSmall?: boolean
    isUppercase?: boolean
    isWideOnMobile?: boolean
    title?: string | null
  }>(),
  {
    ariaLabel: undefined,
    icon: null,
    isBlueOnHover: false,
    isDisabled: false,
    isGreenOnHover: false,
    isIconOnly: false,
    isLoading: false,
    isRedOnHover: false,
    isSmall: false,
    isUppercase: false,
    isWideOnMobile: false,
    title: null
  }
)

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <button
    :aria-label="ariaLabel"
    class="button flex w-full items-center justify-center"
    :class="[
      color,
      {
        'blue-on-hover': isBlueOnHover,
        disabled: isDisabled,
        'green-on-hover': isGreenOnHover,
        'has-icon': icon,
        'icon-only': isIconOnly,
        loading: isLoading,
        'no-text': !title,
        'red-on-hover': isRedOnHover,
        small: isSmall,
        uppercase: isUppercase,
        'wide-on-mobile': isWideOnMobile
      }
    ]"
    @click="emit('click')"
  >
    <template v-if="title">
      {{ title }}
    </template>
    <SvgIcon v-else-if="icon" :name="icon" />
  </button>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

button {
  border: none;
  border-radius: 12px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  outline: none;
  padding: 10px 16px;
  transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, .25);
    cursor: pointer;
  }

  &.disabled {
    background: variables.$body-background-color !important;
    color: variables.$main-light-gray-color !important;
  }

  &.loading {
    color: transparent !important;
    position: relative;

    &:after {
      animation: 1s spin linear infinite;
      border: 2px solid transparent;
      border-bottom-color: white;
      border-radius: 50%;
      border-top-color: white;
      box-sizing: border-box;
      content: '';
      display: block;
      height: 1em;
      left: calc(50% - .5em);
      position: absolute;
      top: calc(50% - .5em);
      width: 1em;
    }

    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  &.disabled,
  &.loading {
    cursor: auto;
    pointer-events: none;
  }

  &.small {
    padding: 10px 16px;
    width: auto;

    &.has-icon.no-text,
    &.icon-only {
      font-size: 24px;
    }

    &.has-icon.no-text {
      padding: 10px;
    }

    &.icon-only {
      background: transparent;
      border: none !important;
      height: 32px;
      padding: 0;
      width: 32px;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.wide-on-mobile {
    @media screen and (max-width: 480px) {
      width: 100% !important;
    }
  }

  @each $class, $color in (
    'black': black,
    'blue': variables.$main-blue-color,
    'body-background': variables.$body-background-color,
    'gray': variables.$main-gray-color,
    'green': variables.$main-green-color,
    'light-gray': variables.$main-light-gray-color,
    'light-yellow': variables.$main-light-yellow-color,
    'red': variables.$main-red-color,
    'white': white
  ) {
    &.#{$class} {
      background: $color;

      &.icon-only {
        color: $color;
      }
    }
  }

  &.black,
  &.blue,
  &.green,
  &.gray,
  &.red {
    color: white;
  }

  &.body-background,
  &.gray,
  &.light-gray {
    transition: color .25s ease-in-out;

    &.blue-on-hover:hover {
      color: variables.$main-blue-color;
    }

    &.green-on-hover:hover {
      color: variables.$main-green-color;
    }

    &.red-on-hover:hover {
      color: variables.$main-red-color;
    }
  }

  &.body-background,
  &.light-gray {
    color: variables.$main-gray-color;
  }

  &.white {
    &.green-on-hover:hover {
      border: 1px solid variables.$main-green-color;
      color: variables.$main-green-color;
    }

    &.loading {
      &:after {
        border-bottom-color: variables.$main-green-color;
        border-top-color: variables.$main-green-color;
      }
    }
  }
}
</style>
