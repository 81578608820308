import type { ResourceObject, Response } from '~/types/json-api'

type ResourceIdentifierObject = { type: string, id: string }
type RelationshipObject = { data: ResourceIdentifierObject | ResourceIdentifierObject[] | null }

export default class JsonApiHelper {
  public static denormalizeResponse<T> (response: T): T {
    const { data, included } = response as Response

    if (data instanceof Array) {
      data.forEach((x: ResourceObject): void => this.fillRelatedObjects(x, included))
    } else {
      this.fillRelatedObjects(data, included)
    }

    return response
  }

  private static fillRelatedObjects (resourceObject: ResourceObject, included?: ResourceObject[]): void {
    const { relationships } = resourceObject

    if (!relationships) {
      return
    }

    Object.values(relationships).forEach(
      (x: RelationshipObject): void => {
        const { data } = x

        if (data instanceof Array) {
          x.data = data.map((y: ResourceIdentifierObject): ResourceObject => this.findResourceObject(y, included!))
        } else if (data !== null) {
          x.data = this.findResourceObject(data, included!)
        }
      }
    )
  }

  private static findResourceObject (
    resourceIdentifierObject: ResourceIdentifierObject, included: ResourceObject[]
  ): ResourceObject {
    const { id, type } = resourceIdentifierObject
    const resourceObject = included.find((x: ResourceObject): boolean => x.type === type && x.id === id)!
    this.fillRelatedObjects(resourceObject, included)

    return resourceObject
  }
}
