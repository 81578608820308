import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type {
  CreateTestResultRequest,
  CreateTestResultResponse,
  GetTestResultResponse,
  GetTestResultsResponse
} from '~/types/tests'

export default class TestResults extends HttpFactory {
  private readonly resource = 'v1/testResults'

  public createTestResult (data: CreateTestResultRequest): Promise<CreateTestResultResponse> {
    return this.$fetch<CreateTestResultResponse>(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getTestResult (recommendationId: string): Promise<GetTestResultResponse> {
    return this.$fetch<GetTestResultResponse>(
      `${this.resource}/${recommendationId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getTestResults (params: object): Promise<GetTestResultsResponse> {
    return this.$fetch<GetTestResultsResponse>(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }
}
