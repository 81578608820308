import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'

export default class CategoryFaqs extends HttpFactory {
  private readonly resource = 'v1/categoryFaqs'

  public getCategoryFaqs (params: object, authScope: AuthScope | null = null) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }
}
