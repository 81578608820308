<script lang="ts" setup>
import type { Component } from 'vue'

const props = defineProps<{
  name: string
}>()

const emptyIconComponent: Component = { template: '<svg xmlns="http://www.w3.org/2000/svg" />' }

const svgComponent = computed(() => {
  const [directory, file] = props.name.split('/')

  // noinspection TypeScriptCheckImport
  return defineAsyncComponent(
    {
      delay: 0,
      loader: () => import(`~/assets/img/icons/${directory}/${file}.svg`)
        .catch((error) => {
          console.error(error)

          return emptyIconComponent
        }),
      loadingComponent: emptyIconComponent,
      suspensible: false
    }
  )
})
</script>

<template>
  <component :is="svgComponent" />
</template>

<style lang="scss" scoped>
svg {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
}
</style>
