import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Tags extends HttpFactory {
  private readonly resource = 'v1/tags'

  public createTag (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getTag (tagId: string) {
    return this.$fetch(`${this.resource}/${tagId}`)
  }

  public getTags (params: object) {
    return this.$fetch(this.resource, { params })
  }

  public updateTag (tagId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${tagId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteTag (tagId: string) {
    return this.$fetch(
      `${this.resource}/${tagId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
