import Vue3Toastify, { toast, type ToastContainerOptions } from 'vue3-toastify'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    Vue3Toastify,
    {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
      icon: false,
      position: toast.POSITION.TOP_CENTER,
      theme: toast.THEME.COLORED,
      transition: toast.TRANSITIONS.SLIDE
    } as ToastContainerOptions
  )

  return {
    provide: { toast }
  }
})
