import {getAuthHeaders} from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import AuthScope from '~/enums/auth-scope'

export default class Consultations extends HttpFactory {
  private readonly resource = 'v1/consultations'

  public acceptConsultation (consultationId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${consultationId}/accept`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Specialist) }
      }
    )
  }

  public cancelConsultation (consultationId: string, data: object, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${consultationId}/cancel`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }

  public getConsultation (consultationId: string, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${consultationId}`,
      { headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getConsultations (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public rescheduleConsultation (consultationId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${consultationId}/reschedule`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Client) }
      }
    )
  }
}
