import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      (window as any).savedPosition = savedPosition

      return { top: 0 } // savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return from.path === to.path ? {} : { top: 0 }
  }
}
