import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'

export default class Suppliers extends HttpFactory {
  private readonly resource = 'v1/suppliers'

  public createSupplier (data: object, authScope: AuthScope) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }

  public getSupplier (supplierId: string, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${supplierId}`,
      { headers: getAuthHeaders(this.authStore.token, authScope) } // include: 'user'?
    )
  }

  public getSuppliers (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateSupplier (supplierId: string, data: object, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${supplierId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }

  public deleteSupplier (supplierId: string, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${supplierId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }
}
