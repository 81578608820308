import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Categories extends HttpFactory {
  private readonly resource = 'v1/categories'

  public createCategory (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getCategory (categoryId: string, params?: object) {
    return this.$fetch(
      `${this.resource}/${categoryId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getCategories (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateCategory (categoryId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${categoryId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteCategory (categoryId: string) {
    return this.$fetch(
      `${this.resource}/${categoryId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
