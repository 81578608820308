import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Certificates extends HttpFactory {
  private readonly resource = 'v1/certificates'

  public createCertificate (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getCertificate (certificateId: string) {
    return this.$fetch(
      `${this.resource}/${certificateId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getCertificates (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateCertificate (certificateId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${certificateId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteCertificate (certificateId: string) {
    return this.$fetch(
      `${this.resource}/${certificateId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
