import { defineStore } from 'pinia'

type CartProduct = { id: string, quantity: number }
type State = { cartTotalItems: number, cartProducts: CartProduct[], wishListTotalItems: number }

const initialState = (): State => ({ cartTotalItems: 0, cartProducts: [], wishListTotalItems: 0 })

export const useProductsStore = defineStore('products', {
  state: () => initialState(),

  getters: {
    getCartProductQuantity: (state: State) => (id: string): number | null => {
      return state.cartProducts.find((x: CartProduct): boolean => x.id === id)?.quantity || null
    }
  },

  actions: {
    addCartProduct ({ id, quantity = 1 }: CartProduct): void {
      this.cartProducts.push({ id, quantity })
    },
    clearCartProducts (): void {
      this.cartProducts.length = 0
    },
    decrementCartTotalItems (quantity = 1): void {
      if (quantity > this.cartTotalItems) {
        this.cartTotalItems = 0
      } else {
        this.cartTotalItems -= quantity
      }
    },
    decrementWishListTotalItems (quantity = 1): void {
      if (quantity > this.wishListTotalItems) {
        this.wishListTotalItems = 0
      } else {
        this.wishListTotalItems -= quantity
      }
    },
    incrementCartTotalItems (quantity = 1): void {
      this.cartTotalItems += quantity
    },
    incrementWishListTotalItems (quantity = 1): void {
      this.wishListTotalItems += quantity
    },
    removeCartProduct (id: string): void {
      const cartProductIndex = this.cartProducts.findIndex((x: CartProduct): boolean => x.id === id)

      if (cartProductIndex !== -1) {
        this.cartProducts.splice(cartProductIndex, 1)
      }
    },
    setCartProductQuantity ({ id, quantity }: CartProduct): void {
      const cartProduct = this.cartProducts.find((x: CartProduct): boolean => x.id === id)

      if (cartProduct) {
        cartProduct.quantity = quantity
      } else {
        this.cartProducts.push({ id, quantity })
      }
    },
    setCartTotalItems (cartTotalItems: number): void {
      this.cartTotalItems = cartTotalItems
    },
    setWishListTotalItems (wishListTotalItems: number): void {
      this.wishListTotalItems = wishListTotalItems
    }
  }
})
