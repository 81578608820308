import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45add_45trailing_45slash_45global from "/usr/src/app/middleware/01.add-trailing-slash.global.ts";
import _02_45redirect_45to_45new_45page_45global from "/usr/src/app/middleware/02.redirect-to-new-page.global.ts";
import _03_45redirect_45to_45app_45marketplace_45global from "/usr/src/app/middleware/03.redirect-to-app-marketplace.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45add_45trailing_45slash_45global,
  _02_45redirect_45to_45new_45page_45global,
  _03_45redirect_45to_45app_45marketplace_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}