import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class PromoCodes extends HttpFactory {
  private readonly resource = 'v1/promoCodes'

  public createPromoCode (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getPromoCode (promoCodeId: string) {
    return this.$fetch(
      `${this.resource}/${promoCodeId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getPromoCodes (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updatePromoCode (promoCodeId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${promoCodeId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deletePromoCode (promoCodeId: string) {
    return this.$fetch(
      `${this.resource}/${promoCodeId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
