/**
 * Class RetailRocketApiService
 */
export default class RetailRocketApiService {
  public static addToBasket (productId: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.addToBasket(Number(productId)))
  }

  public static categoryView (categoryId: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.categoryView(Number(categoryId)))
  }

  public static groupView (productsIds: string[]): void {
    // @ts-ignore
    this.execute(() => window.rrApi.groupView(productsIds.map((x: string): number => Number(x))))
  }

  public static render (): void {
    try {
      // @ts-ignore
      window.retailrocket.markup.render()
    } catch (e) {
    }
  }

  public static search (query: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.search(query))
  }

  public static setEmail (email: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.setEmail(email))
  }

  public static setTransaction (email: string, orderId: string, items: any): void {
    this.execute(() => {
      if (email) {
        // @ts-ignore
        window.rrApi.setEmail(email)
      }

      // @ts-ignore
      window.rrApi.order({ transaction: orderId, items })
    })
  }

  public static view (productId: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.view(Number(productId)))
  }

  public static welcomeSequence (email: string): void {
    // @ts-ignore
    this.execute(() => window.rrApi.welcomeSequence(email))
  }

  private static execute (callback: Function): void {
    // @ts-ignore
    (window.rrApiOnReady = window.rrApiOnReady ?? []).push(
      function () {
        try {
          callback()
        } catch (e) {
          console.error(e)
        }
      }
    )
  }
}
