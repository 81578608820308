import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Warehouses extends HttpFactory {
  private readonly resource = 'v1/warehouses'

  public createWarehouse (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getWarehouse (warehouseId: string) {
    return this.$fetch(
      `${this.resource}/${warehouseId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getWarehouses (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateWarehouse (warehouseId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${warehouseId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteWarehouse (warehouseId: string) {
    return this.$fetch(
      `${this.resource}/${warehouseId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
