import constants from '~/constants'

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === '/app/') {
    const userAgent = import.meta.server ? useRequestHeaders()['user-agent'] : navigator.userAgent
    const { appStore, googlePlay } = constants.appUrls

    if (/android/i.test(userAgent || '')) {
      return navigateTo(googlePlay, { external: true })
    }

    if (/iPhone|iPad|iPod/i.test(userAgent || '')) {
      return navigateTo(appStore, { external: true })
    }

    return navigateTo(appStore, { external: true })
  }
})
