import type { StatusColor } from '~/types'

enum SpecialistApplicationStatus {
  InProcess = 1,
  Approved = 2,
  Rejected = 3
}

const { Approved, InProcess, Rejected } = SpecialistApplicationStatus

const colorMapping: Map<SpecialistApplicationStatus, StatusColor> = new Map([
  [InProcess, 'green'],
  [Approved, 'blue'],
  [Rejected, 'gray']
])

const nameMapping = new Map([
  [InProcess, 'В обработке'],
  [Approved, 'Одобрена'],
  [Rejected, 'Отклонена']
])

namespace SpecialistApplicationStatus {
  export const SpecialistApplicationStatus = [InProcess, Approved, Rejected]

  export function getColor (promoCodeApplicationStatus: SpecialistApplicationStatus): StatusColor {
    return colorMapping.get(promoCodeApplicationStatus)!
  }

  export function getName (promoCodeApplicationStatus: SpecialistApplicationStatus): string {
    return nameMapping.get(promoCodeApplicationStatus)!
  }
}

export default SpecialistApplicationStatus
